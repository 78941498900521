define("discourse/plugins/discourse-group-tracker/discourse/components/group-tracker-topic-status", ["exports", "@glimmer/component", "@ember/service", "discourse-common/helpers/d-icon", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _dIcon, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _GroupTrackerTopicStatus;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let GroupTrackerTopicStatus = _exports.default = (_class = (_GroupTrackerTopicStatus = class GroupTrackerTopicStatus extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "site", _descriptor, this);
      _defineProperty(this, "context", this.args.context);
    }
    get titleName() {
      const trackedGroups1 = this.site && this.site.tracked_groups;
      const trackedGroup1 = trackedGroups1.find(g1 => g1.name === this.context.topic.first_tracked_post.group);
      let title1 = "";
      if (trackedGroup1) {
        const name1 = trackedGroup1.full_name ? trackedGroup1.full_name : trackedGroup1.name;
        title1 = _discourseI18n.default.t("group_tracker.first_group_post", {
          group: name1
        });
      }
      return title1;
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <span class="topic-icon-container">
        {{#if this.context.topic.first_tracked_post}}
          <a
            class="tracked-post group-{{this.context.topic.first_tracked_post.group}}"
            href="{{this.context.topic.url}}/{{this.context.topic.first_tracked_post.post_number}}"
            title={{this.titleName}}
          >
            {{icon
              this.context.topic.firstTrackedPostIcon
              class="first-tracked-post"
            }}
            {{#if this.context.siteSettings.group_tracker_topic_icon}}
              {{icon
                this.context.siteSettings.group_tracker_topic_icon
                class=this.context.siteSettings.group_tracker_topic_icon_class
              }}
            {{/if}}
          </a>
        {{else}}
          {{#if this.context.siteSettings.group_tracker_topic_icon}}
            {{icon
              this.context.siteSettings.group_tracker_topic_icon
              class=this.context.siteSettings.group_tracker_topic_icon_class
            }}
          {{/if}}
        {{/if}}
      </span>
    
  */
  {
    "id": "6FFhX6AT",
    "block": "[[[1,\"\\n    \"],[10,1],[14,0,\"topic-icon-container\"],[12],[1,\"\\n\"],[41,[30,0,[\"context\",\"topic\",\"first_tracked_post\"]],[[[1,\"        \"],[10,3],[15,0,[29,[\"tracked-post group-\",[30,0,[\"context\",\"topic\",\"first_tracked_post\",\"group\"]]]]],[15,6,[29,[[30,0,[\"context\",\"topic\",\"url\"]],\"/\",[30,0,[\"context\",\"topic\",\"first_tracked_post\",\"post_number\"]]]]],[15,\"title\",[30,0,[\"titleName\"]]],[12],[1,\"\\n          \"],[1,[28,[32,0],[[30,0,[\"context\",\"topic\",\"firstTrackedPostIcon\"]]],[[\"class\"],[\"first-tracked-post\"]]]],[1,\"\\n\"],[41,[30,0,[\"context\",\"siteSettings\",\"group_tracker_topic_icon\"]],[[[1,\"            \"],[1,[28,[32,0],[[30,0,[\"context\",\"siteSettings\",\"group_tracker_topic_icon\"]]],[[\"class\"],[[30,0,[\"context\",\"siteSettings\",\"group_tracker_topic_icon_class\"]]]]]],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"context\",\"siteSettings\",\"group_tracker_topic_icon\"]],[[[1,\"          \"],[1,[28,[32,0],[[30,0,[\"context\",\"siteSettings\",\"group_tracker_topic_icon\"]]],[[\"class\"],[[30,0,[\"context\",\"siteSettings\",\"group_tracker_topic_icon_class\"]]]]]],[1,\"\\n\"]],[]],null]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-group-tracker/discourse/components/group-tracker-topic-status.js",
    "scope": () => [_dIcon.default],
    "isStrictMode": true
  }), _GroupTrackerTopicStatus), _GroupTrackerTopicStatus), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});